.sl-complete {
  background-color: #357abd;
}

.sl-scanning {
  background-color: #4cae4c;
}

.sl-pending {
  background-color: #303030;
}

.sl-in-prog {
  background-color: #999999;
}

.sl-critical,
.ch-ss-label-critical {
  background-color: #cc1818;
}

.sl-high,
.ch-ss-label-high {
  background-color: #cc1818;
}

.sl-moderate,
.ch-ss-label-moderate,
.ch-ss-label-medium {
  background-color: #f6be00;
}

.sl-low,
.ch-ss-label-low {
  background-color: #4cae4c;
}

.sl-informational {
  background-color: #1d4b68;
}

.sl-na {
  background-color: #b7b7b7;
}
