.new-item-card,
.new-item-card-wide {
  cursor: pointer;
  text-align: center;
  width: 185px;
  height: 287px;
  margin: 10px;
  border: 1px solid #d4d4d4d4;
  box-shadow: 2px 4px 10px 5px #e4e4e4;
  padding: 10px;
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px;
  border-image: none;
  border-color: #e7eaec;
  border-style: solid solid none;
  border-width: 1px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-left: 5px groove #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
}

.new-item-card-wide {
  width: 275px;
  height: 350px;
}

.new-item-card-not-clickable {
  pointer-events: none;
}

.css-plus-container {
  margin: 20px 40px;
  font-size: 40px;
  color: white;
  font-weight: 900;
  background: green;
  border-radius: 100px;
}

.new-item-card-wide .css-plus-container {
  margin: 30px 85px;
}

.new-item-card-wide .new-item-card-caption {
  margin-top: 30px;
}

.new-item-card-text-name {
  padding-top: 5px;
  line-height: 1.05em;
  height: 124px;
  overflow: hidden;
  font-size: 0.85em;
  font-weight: 700;
  text-align: center;
  word-break: break-word;
}
