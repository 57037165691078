.add-remove-my-vendor-div {
  display: flex;
  margin-right: 25px;
}

.add-remove-my-vendor-btn {
  color: white;
  border: none;
  outline: none;
}

.remove-my-vendor-icon {
  vertical-align: top;
}
