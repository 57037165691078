.product-image-wrapper {
    padding: 15px;
    margin: auto;
    margin-top: -5px;
    text-align: center;
  }

  .product-image {
    height: 125px;
    width: 125px;
  }

  .product-image-name {
    min-width: fit-content;
  }

  .product-name {
    text-align: center; 
    max-width: 100%; 
    font-size: 25px; 
    height: fit-content;
  }

  .product-description-link-wrapper {
    margin-top: -12px; 
    min-width: 250px;
    max-width: 250px;
  }

  .product-description-link {
    text-align: center; 
    max-width: 100%; 
    font-size: 16px; 
    overflow: visible; 
    height: fit-content;
  }

  .product-link {
    color: #327688; 
    text-decoration: none;
  }

  .product-reports {
      width: max-content
  }