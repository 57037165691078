#my-orders-table .nav-item {
  cursor: pointer;
}

#my-orders-table .nav-item .nav-link {
  color: rgb(33, 37, 41);
}

#my-orders-table .nav-item .nav-link.active {
  color: rgb(255, 255, 255);
}

#my-orders-table .nav-item .nav-link:hover {
  color: #18bc9c;
}

#my-orders-table .nav-pills {
  margin-bottom: 20px;
}