:root {
    --posternHighSeverity: #ed5565;
    --posternHighSeverityLight: #f3909b;
    --posternLowSeverity: #1ab394;
    --posternLowSeverityLight: #6aceb9;
    --posternModerateSeverity: #e88f24;
    --posternMediumSeverity: #e88f24;
    --posternNumberBackground: #004d70;
    --posternGreenCircle: #008000;
    --posternYellowCircle: #ffc104;
    --posternRedCircle: #e84d3d;
    --brandPrimary: #173059;
    --brandText: #fff;
    --brandSecondary: #e88f24;
    --brandHover: #173059;
    --brandHoverText: #fff;
}