  [aria-describedby="footnote-label"] {
    counter-increment: footnotes;
    text-decoration: none;
    color: 'black';
    cursor: default;
    outline: none;
  }
  
  [aria-describedby="footnote-label"]::after {
    content: '[' counter(footnotes) ']';
    vertical-align: super;
    font-size: 0.5em;
    margin-left: 2px;
    text-decoration: none;
    cursor: pointer;
    color: black;
  }
