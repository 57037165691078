.borderless-table,
.borderless-item {
  border: 0;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}

.print-table {
  padding-left: 5px;
  padding-right: 5px;
}

.print-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  border-top: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}

.print-col-sm-1 {
  max-width: 318px;
  grid-column-end: span 1;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc !important;
}

p.print-col-sm-1 {
  padding: 10px;
  margin-bottom: -1px;
}

.print-col-align {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.print-title-align {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.m-auto-c {
  width: fit-content;
  margin: auto;
  display: block;
}

.manual-option-text {
  width: fit-content;
  font-size: 14px;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}

.ddrr-vendor-logo {
  margin: 10px 0;
  max-width: 200px;
  max-height: 200px;
}

#ddrr-print-cover-page {
  padding-top: 100px;
  text-align: right;
  margin: 50px;
}

#ddrr-print-cover-page table {
  margin-top: 40px;
}

.ddrr-cover-page-total-score {
  padding: 5px 0;
}

#ddrr-print-cover-page .severity-flag {
  display: inline;
}

.ddrr-cover-risk-footnote {
  text-align: left;
}

#ddrr-inherent-risk-score-print {
  height: 135px;
  display: flex;
  justify-content: center;
}

.report-info-div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
}

.report-info {
  border-bottom: solid #173059;
  width: 60%;
}

.vendor-name {
  font-size: 24px;
  font-weight: 600;
}

.report-name {
  font-size: 20px;
  font-weight: 400;
}

.last-reported,
.printed-date {
  font-size: 16px;
  padding-top: 5px;
}

.print-nav-fortress-logo {
  border: none !important;
  background: #173059 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 60px !important;
  width: 100px !important;
  height: 160px !important;
  margin-right: 50px;
}
#executive-order-status-print {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.compliance-alert-heder {
  font-weight: 700;
}
