footer {
  visibility: hidden;
}

body{
  height: 100%;
  margin: 0;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}

.PasswordCompare {
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  color: #090809;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 1;
  position: relative;
}

.PasswordCompare.is-strength-0 .Pa.PasswordCompare {
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  color: #090809;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  position: relative;
}

.PasswordCompare-input-reset-pw {
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  padding: 14px 0 12px 14px;
  /* width: calc(85% - 28px); */
  width: 100%;
}

.PasswordCompare-strength-desc {
  font-style: italic;
  color: white;
  padding: 14px 12px;
  position: absolute; top: 1px; right: 0;
  text-align: right;
  transition: color 250ms ease-in-out;
  width: 40%;
}

.PasswordCompare.is-strength-0 .PasswordCompare-strength-desc { color: #D1462F; }
.PasswordCompare.is-strength-1 .PasswordCompare-strength-desc { color: #D1462F; }
.PasswordCompare.is-strength-2 .PasswordCompare-strength-desc { color: #57B8FF; }
.PasswordCompare.is-strength-3 .PasswordCompare-strength-desc { color: #57B8FF; }
.PasswordCompare.is-strength-4 .PasswordCompare-strength-desc { color: #2FBF71; }

