.subscriber-details-table .subscriber-details-table-columns {
  vertical-align: middle;
}

.subscriber-details-table-columns,
.subscriber-details-table-columns td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.subscriber-details-body {
  border: 1px solid #dee2e6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 15px;
  margin-top: -1px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px;
}

.subscriber-details-button-ul {
  margin-bottom: -1px;
}

.subscriber-details-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.subscriber-details-claim-reset-btns {
  margin-left: 10px;
}

.subscriber-details-reset-tokens-on-hover-text {
  margin-bottom: 0px;
  text-align: center;
}

.subscriber-details-table-deleted-transaction-icon {
  font-size: large;
}

.subscriber-details-table-is-deleted-column {
  text-align: center;
}

#adjust-tokens-input {
  padding-left: 13px;
  width: 50%;
}

.react-bootstrap-table > table > tbody > tr > th > div > label {
  width: 100%;
}
