.p-vo-flex-holder{
    display: flex;
    flex-wrap: wrap;
}

.p-vo-flex-holder p{
    padding-bottom: 4px;
    margin-bottom: 0px;
}

.meta-info{
    max-width: 525px;
    padding: 20px;
    padding-left: 0px;
}

.provenance-risk{
    padding:20px;
    padding-left: 0px;
}

.security-risk{
    padding:20px;
    padding-left: 0px;
}

#provenance-vendor-overview .p-title{
    font-size: 25px;
    font-weight: 700;
}
#provenance-vendor-overview .p-name{
    font-size: 22.5px;
    font-weight: 700;
}
#provenance-vendor-overview .p-description-title{
    font-size: 18px;
    font-weight: 700;
}

.provenance-risk h3, .security-risk h3{
    padding-top: 10px;
}

.provenance-risk span, .security-risk span{
    padding-bottom: 4px;
    display: block;
}