div.compliance-section-container {
  padding-bottom: unset;
}

.compliance-table {
  width: 100%;
}

.compliance-thead {
  border-bottom: 1px solid #a2a2a2;
}

.compliance-tr:not(:last-child) {
  border-bottom: 1px solid #c9c9c9;
}

.compliance-tr-empty td p {
  margin-top: 15px;
  text-align: center;
}

.no-cmre-data-notice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
