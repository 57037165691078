.statusBtnBase{
    min-width: 150px;
    font-weight: 600;
    color: #fff;
    padding: 3px 0px;
}
.btn-circle{
    width: 14.25px;
    height: 14.25px;
    background: white;
    margin-right: 10px;
    display: inline-block !important;
    border-radius: 500px;
}

.btn-circle-hidden{
    width: 14.25px;
    height: 14.25px;
    margin-right: 10px;
    display: inline-block !important;
    display: hidden;
}

#available-reports table {
    margin-bottom: 0px;
}

.statusGreen{
    background: #31af31;
}

.statusGreen .btn-circle {
    background-color:#434343;
}

.statusBlue{
    /* background: #0052cc; */
    background: #008bff;
}

.statusBlue .btn-circle {
    background-color: #434343;
}
 
.statusOrange{
    background: #ff9900;
}

.statusOrange .btn-circle {
    background-color: #434343;
}

.statusGray{
    background:#434343;
}

.statusError {
    background-color: #f03e3e;
}

.statusGray .btn-circle {
    background-color: #fff;
}

.statusBtnBase.btn{
    text-align: left !important;
}

#available-reports th,
#available-reports td{
    text-align: center;
}