.severity-pill{
    display: inline-block;
    min-width: 80px;
    max-width: fit-content;
    text-align: center;
    border-style: solid;
    border-width: 1px 1px 1px 10px;
    border-color: #000;
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff !important;
    color: #000 !important;
    text-transform: capitalize;
}

.severity-pill.low{ border-color: var(--posternLowSeverity);}
.severity-pill.medium{ border-color: var(--posternModerateSeverity);}
.severity-pill.moderate{ border-color: var(--posternModerateSeverity);}
.severity-pill.high{ border-color: var(--posternHighSeverity);}
.severity-pill.critical{ border-color: var(--posternHighSeverity);}