.postern-top-banner {
  width: fit-content;
  max-width: 50%;
  padding: 40px 20px 10px 20px;
  margin-bottom: 40px;
  color: #fff;
  background-color: #e88f24;
  border-bottom: 4px solid #004d70;
  box-shadow: 5px 5px 10px grey;
  min-width: 350px;
  line-height: 1;
}

.vendor-name {
  font-size: 34px;
  font-weight: 600;
}

.report-type {
  font-size: 28px;
  font-weight: 400;
}

.last-reported-date {
  font-size: 18px;
  padding-top: 5px;
}
