#postern-print-button{
    width: 100%;
    padding-bottom: 7.5px;
}

#postern-print-button img{
    cursor: pointer;
    display: block;
    margin: auto;
    width: 70px;
}