.subscriber-users-table .subscriber-users-table-columns,
.admin-users-table .admin-users-table-columns,
.subscribers-table .subscribers-table-columns {
  vertical-align: middle;
}

.subscriber-users-table-columns,
.subscriber-users-table-columns td,
.admin-users-table-columns,
.admin-users-table-columns td,
.subscribers-table-columns,
.subscribers-table-columns td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.subscriber-users-table-is-deleted-column,
.admin-users-table-is-deleted-column,
.subscribers-table-is-deleted-column {
  text-align: center;
}

.admin-users-table-is-deleted-column {
  width: 5%;
}

.admin-users-table-is-sso-column {
  width: 10%;
}

.deleted-instance-icon {
  font-size: large;
}
