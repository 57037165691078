#catalog-home{
    padding: 0 40px;
}

@media only screen and (min-width: 2050px) {
  #catalog-home {
    padding: 0 200px;
  }
}

#catalog-home .card-group-1{
    display: flex;
    flex-wrap: wrap;
    max-width: 620px;
}

#catalog-home .card-group-2{
    display: flex;
    max-width: 1260px;
    flex-wrap: wrap;
}


#catalog-home .card-group-1 .card,
#catalog-home .card-group-2 .card{
    width: 300px;
    max-width: 300px;
    margin-right: 20px;
    margin-bottom: 20px;;
}

#catalog-home .card-group-1 .card:nth-last-child(1),
#catalog-home .card-group-2 .card:nth-last-child(1){
    margin-right: 0;
}