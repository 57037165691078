#provenance-print-cover-page{
    text-align: right;
}

.move-hex-right{
    width: fit-content;
    margin-left: auto;
}

#provenance-print-cover-page .provenance-risk,
#provenance-print-cover-page .security-risk{
    padding: 20px 0 !important;
}

#provenance-print-cover-page .provenance-risk span,
#provenance-print-cover-page .security-risk span{
    padding: 10px 0;
}