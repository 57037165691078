#postern-horizontal {
  display: flex;
  height: 100%;
  width: 100%;
}

.content-level-table {
  width: 100%;
}

.footer-space,
.print-footer {
  display: none;
  height: 0;
}

.redistribution-disclaimer {
  display: block;
  font-weight: bold;
}

@media print {
  @page {
    size: auto;
    margin: 0.24in 0.24in 0.24in 0.24in;
  }

  #postern-horizontal {
    top: 0;
    left: 0;
    position: absolute;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    width: 100vw;
  }

  #postern-nav,
  .redistribution-disclaimer {
    display: none;
  }

  /* #guardian-footer, #guardian-nav, #help_btn{
        display: none;
        height: 0px;
    } */

  .footer-space,
  .print-footer {
    display: unset;
    width: 100%;
    height: 22px;
  }

  .print-footer {
    border-top: 1px solid #000;
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
  }

  .p-print-nav-bottom-text {
    padding-top: 2px;
    width: fit-content;
    display: block;
    margin: auto;
    line-height: 1.5;
    font-size: 0.9em;
    color: #173059;
    font-weight: bolder;
  }
}
