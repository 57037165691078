.user-details-tab-body {
  border: 1px solid #dee2e6;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 15px;
  margin-top: -1px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px;
}

.user-nav-tabs {
  margin-bottom: -1px;
}

.user-nav-tab-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.user-details-sso-checkbox {
  height: 25px;
  width: 15px;
  margin-bottom: 8px;
}

.user-details-sso-checkbox-p {
  margin-bottom: 0px;
}

.user-details-roles-table {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.user-details-roles-revoke-icon {
  color: #81868a;
  cursor: pointer;
}

.user-details-roles-grant-icon {
  cursor: pointer;
}
