#ch-category-tiles {
  margin: 0 auto;
}

#ch-category-tiles .provenance-highlightedTileSection {
  margin-top: 15px;
}

#ch-table {
  width: 35%;
  margin: 0 auto;
}
