#postern-ddrr {
  height: 100%;
  font-size: 11pt;
  line-height: 1.25;
  font-weight: 100;
  font-family: 'Source Sans Pro', sans-serif;
}

.top-banner-section {
  padding: 10px;
}

.ddrr-report-content {
  padding: 30px;
}

#postern-ddrr .sub-section-child {
  position: relative;
}

.overall-vendor-score-center {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 56px);
}

.quadrant-section {
  min-height: 300px;
}

.m-tal-c {
  text-align: center;
}

.print-shown {
  padding: 10px;
}

.company-info-score-section {
  display: flex;
  justify-content: space-between;
}

.company-info-score-section-left-column {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.company-info-score-section-right-column {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
