.p-show {
  display: inline;
}

.p-hide {
  display: none;
}

#postern-nav {
  position: relative;
  min-width: 200px;
  width: 200px;
  padding: 20px;
  background-color: var(--brandPrimary);
  padding-bottom: 45px;
}

#postern-nav,
#postern-nav nav {
  height: 100%;
  max-height: 100%;
}

.p-nav {
  overflow: auto;
  max-height: calc(100% - 210px);
  height: calc(100% - 210px);
  margin-top: 15px;
}

.p-nav li {
  justify-content: center;
  margin-top: 10px;
}

#postern-nav ul {
  list-style: none;
  padding: 0px;
}

#postern-nav .p-nav-link,
#postern-nav .p-nav-link:hover,
#postern-nav .p-nav-link:focus {
  letter-spacing: 0px;
  color: var(--brandText);
  text-decoration: none;
  font-size: 13.5px;
  cursor: pointer;
}

#postern-nav .p-nav-link:hover {
  color: var(--brandHoverText);
  background-color: var(--brandHover);
}

.p-nav-bottom-text {
  font-size: 12px;
  text-align: center;
  color: var(--brandText);
}

.p-nav-bottom-text b {
  font-weight: 700;
  color: var(--brandText);
}

.p-sub-nav-item {
  list-style-type: disc;
  margin-left: 22px;
  padding-left: 6px;
  margin-top: 0px;
  color: #fff;
  word-break: break-word;
  text-align: start;
  font-size: 11px;
}

.expandable-nav-list {
  margin-top: -5px;
}

.p-sub-nav-item .p-nav-link {
  margin-left: -7px;
}

.expand-chevron-more,
.expand-chevron-less {
  cursor: pointer;
}

.p-toc-title {
  display: none;
  color: var(--brandText);
  font-size: 30px;
  font-weight: 700;
}

.nav-fortress-logo-container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.nav-fortress-logo {
  width: 150px;
}

@media print {
  #postern-nav {
    min-width: 288px;
    width: 288px;
    font-weight: 100;
    display: flex;
    justify-content: center;
    padding-top: 168px;
  }

  #postern-nav nav {
    display: block;
    margin: auto;
    height: fit-content;
  }

  .p-nav li {
    display: flex;
  }

  .p-nav-link,
  .p-nav-link:hover,
  .p-nav-link:focus {
    font-size: 14pt !important;
    text-align: center;
    padding: 20px 0;
  }

  .expand-chevron-more,
  .expand-chevron-less,
  .expandable-nav-list,
  .nav-fortress-logo,
  .p-nav-bottom-text {
    display: none;
  }

  .print-hide {
    display: none;
    height: 0;
  }

  .company-description {
    font-style: italic !important;
    color: #7f7f7f !important;
    font-size: 12pt;
    text-align: right;
  }
}
