div.info-hygiene-logo {
  padding: 15px;
  margin: auto;
  margin-top: -5px;
  margin-bottom: -35px;
}

img.info-hygiene-logo {
  height: auto;
  width: auto;
  max-height: 40px;
  max-width: 100px;
}

#info-hygiene-badge {
  color: #ffffff;
  font-size: 1rem;
  min-width: 100px;
  margin-left: 1em;
  padding: 0.25em 1em;
}

.info-hygiene-logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.info-hygiene-logo-wrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.info-hygiene-company-logo {
  max-height: 200px;
}

.info-hygiene-hover-text {
  margin-bottom: 0px;
}

.info-hygiene-waiting-text {
  text-align: left;
}

.info-hygiene-vendor-risk-text {
  font-weight: 600;
  margin-top: 5px;
}

.info-hygiene-bold {
  font-size: 1.15rem;
}
