.fi-content {
  margin-top: 15px;
}

.fi-summary-text p {
  color: gray;
  text-align: center;
}

div:not(.summary-section-container) > .provenance-table > .fi-thead {
  position: sticky;
  top: 39px;
  z-index: 1;
  background-color: white;
}

.fi-thead th {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid gray;
}

.fi-table-row p {
  margin: 0px 0px;
  color: gray;
}

div:not(.summary-section-container) > table.provenance-table {
  margin-top: -12px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
}

.summary-section-container > table.provenance-table {
  margin-top: 0;
}

.provenance-table-row td:last-child a {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
}

.center-display {
  text-align: center;
}

.country-display {
  min-width: 160px;
}

.provenance-table-row-empty p {
  text-align: center;
  margin: 15px 0 0 0;
  color: gray;
}

.fi-map-legends-container {
  display: flex;
  margin: 0 5%;
}

.fi-map-legends-container:not(.country-legend-only) {
  justify-content: center;
}

.provenance-cybermap-marker-legend,
.provenance-cybermap-marker-legend > div:not(:last-child) {
  padding-right: 10px;
}

.fi-legend-spacer {
  flex: 1;
}

.no-cmre-data-notice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1775px) {
  .provenance-cybermap-marker-legend > div {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 1485px) {
  tr.provenance-table-row td:last-child a {
    max-width: 200px;
  }
}
