#postern-print-cover-page, .print-nav-fortress-logo{ 
    display: none;
    height: 0px;
}

.postern-print-cover-page-content{
    padding: 0 20px;
    width: 100%;
}

@media print {
    #postern-print-cover-page{ 
        display: flex;
        height: calc(100vh - 23px);
        width: 100%;
    }
    
    #postern-print-cover-page #postern-nav{
        display: flex;
        height: 100%;
    }

    .print-nav-fortress-logo{
        display: block;
        margin-left: auto;
        max-width: 200px;
        max-height: 200px;
        width: 200px;
        height: 200px;
        background-color: var(--brandPrimary);
        border: 20px solid var(--brandPrimary);
        margin-bottom: 20px;
    }
}