.sa-maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sa-maintenance-icon {
  padding-top: 100px;
  padding-bottom: 30px;
}

.sa-maintenance-text {
  padding-bottom: 100px;
}

.sa-section-locked {
  text-align: center;
}

.sa-section-locked-icon {
  position: relative;
  top: -2px;
  left: -3px;
}

.sa-section-locked-text {
  padding-top: 45px;
  display: block;
}

.sa-claim-page-button {
  padding-bottom: 100px;
  text-align: center;
}

.sa-file-icon {
  font-size: 80px;
}

.sa-container {
  border-radius: 5px;
  padding: 10px 15px 5px 15px;
  margin: 0;
}

.sa-modal-close {
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 7px;
  top: 0;
}

.sa-modal-label {
  color: grey;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sa-modal-label-tip {
  font-weight: normal;
  font-style: italic;
}

.sa-option-label {
  margin-left: 10px;
  margin-bottom: 0;
}

.sa-modal-btn-container {
  display: table;
  width: 100%;
  min-height: 5px;
}

.sa-file-history-modal .sa-modal-btn-continer {
  margin-top: -15px;
}

.sa-modal-btn-container > .sa-modal-btns-right {
  text-align: right;
  display: table-cell;
}

.sa-modal-btn-container > .sa-modal-btns-left {
  text-align: left;
  display: table-cell;
}

button.sa-modal-delete-btn {
  background-color: #ea7272;
  border-color: #ea7272;
  color: white;
}

button.sa-modal-delete-btn:hover,
button.sa-modal-delete-btn:not(:disabled):not(.disabled):active {
  background-color: red;
  border-color: red;
  color: white;
}

button.sa-modal-submit-btn:not(:disabled) {
  border-color: green;
  color: black;
}

button.sa-modal-submit-btn:not(:disabled):hover,
button.sa-modal-submit-btn:not(:disabled):not(.disabled):active {
  background-color: green;
  color: white;
}

.sa-modal-delete-confirm-msg {
  color: red;
  font-weight: bold;
}

.sa-modal-delete-confirm {
  text-align: center;
  margin-bottom: 15px;
}

.sa-modal-cancel-btn {
  margin-right: 15px;
}

.sa-file-div {
  max-width: 90px;
  max-height: 70px;
  margin-right: 15px;
  cursor: pointer;
  margin-left: 10px;
  align-self: center;
}

.sa-file-info-div {
  display: flex;
  margin-top: 10px;
}

.sa-file-privacy-icon {
  margin-left: -10px;
  margin-right: 10px;
  font-size: 20px;
}

.sa-file-name {
  overflow-wrap: break-word;
  width: 100%;
  text-align: center;
}

.sa-cat-section-row {
  padding: 15px 0;
}

.sa-default-box {
  width: 150px;
  height: 100px;
  border: 1px dashed black;
  text-align: center;
  margin-left: 10px;
  align-self: center;
  cursor: pointer;
}

.sa-default-box-plus {
  font-size: x-large;
  margin-top: 37px;
  color: limegreen;
}

.sa-modal-input {
  border: 1px solid black;
  color: black;
}

.sa-modal-privacy,
.sa-modal-role-access {
  margin-bottom: 10px;
  margin-left: 35px;
  margin-right: 35px;
}

.sa-modal-privacy .sa-modal-option.privacy-warning {
  background-color: #ffcaca;
}

.sa-modal-option {
  padding: 2px 10px;
}

.sa-modal-option:hover {
  cursor: pointer;
  background: #d7f2ed;
}

.sa-modal-option:hover > label,
.sa-modal-option:hover > input {
  cursor: pointer;
}

.sa-file-icon {
  font-size: 80px;
}

.public-privacy-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cf382e;
  font-weight: bold;
  margin: -10px 0 10px 0;
}

.public-privacy-warning > div {
  margin-left: 10px;
}

.public-privacy-warning-icon {
  font-size: 1.6rem;
  color: #cf382e;
}

/* Modal */
.sa-modal-style {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

.ReactModal__Overlay {
  z-index: 2;
  justify-content: center;
}

.sa-entry-form {
  display: table;
  width: 100%;
}

.sa-entry-form > .sa-entry-form-row {
  display: table-row;
}

.sa-entry-form-row > div {
  display: table-cell;
  padding-bottom: 15px;
}

.sa-entry-form-row > div:first-child {
  padding-right: 15px;
  width: 150px;
}

.sa-files-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 85%;
  margin: 0 16px;
}

.sa-files-row.no-create {
  width: 100%;
}

.file-pending-req-cnt {
  color: #ff9c04;
  font-weight: bold;
}

.file-approved-req-cnt {
  color: #008000;
  font-weight: bold;
}

.file-denied-req-cnt {
  color: #e74c3c;
  font-weight: bold;
}

.file-request-banner-msg {
  font-weight: bold;
}

.file-request-banner-msg .sa-file-privacy-icon {
  position: absolute;
  right: 0;
}

.sa-access-requests-table-columns {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
