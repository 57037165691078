#risk-observations {
  font-size: 11pt;
  font-weight: 100;
  line-height: 1.25;
  font-family: "Source Sans Pro",sans-serif;
  margin-bottom: 40px;
}

#risk-observations .severity-pill {
  padding: 2px 5px;
  min-width: 100%;
}

#risk-observations .horiz-centered-cell {
  text-align: center;
}

#risk-observations .bold-footer {
  font-weight: bold;
}