.hex-severity{
    background-color: #000;
    clip-path: polygon(25% 0,75% 0,100% 50%,75% 100%,25% 100%,0 50%);
    height: 40px;
    width: 50px;
    font-size: 15px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
    font-weight: 700;
    line-height: 40px;
}

.hex-severity.h{
    background-color: var(--posternHighSeverity);
}

.hex-severity.l{
    background-color: var(--posternLowSeverity);
}

.hex-severity.m{
    background-color: var(--posternModerateSeverity);
}