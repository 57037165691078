#unclaimed-vendor-contact-details-modal {
  margin: auto;
  display: block;
  font-size: 20px;
  width: 65%;
  box-shadow: 0 5px 50px -4px #aaa;
  border-radius: 8px;
}

.unclaimed-vendor-contact-details-modal-alert-div {
  background-color: #d6eaf8;
  font-size: 12px;
}

.unclaimed-vendor-contact-details-modal-alert-icon-div {
  display: flex;
}

.unclaimed-vendor-contact-details-modal-alert-icon {
  color: blue;
  font-size: large;
}
