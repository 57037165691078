.password-strength div {
  margin-bottom: 5px;
}

.not-met {
  color: red;
}

.met {
  color: green;
}

div.met input {
  border: green solid 2px !important;
}

div.not-met input {
  border: orange solid 2px !important;
}

div.requirements {
  font-size: smaller;
}
