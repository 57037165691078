#postern-provenance {
    height: 100%;
}

.provenance-tile-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#postern-provenance a,
#postern-provenance div,
#postern-provenance h1,
#postern-provenance h2,
#postern-provenance h3,
#postern-provenance h4,
#postern-provenance h5,
#postern-provenance li,
#postern-provenance p {
    font-family: "Source Sans Pro",sans-serif;
}

#postern-provenance h3{
    font-weight: 700;
    font-size: 20px;
}

#postern-provenance footer p {
    text-align: center;
    font-weight: 100;
    font-size: 12px;
    color: #636363;
}

#postern-provenance .footer-p-wrapper {
  margin-top: 32px;
  margin-bottom: 16px;
}

#postern-provenance .footer-multi-p {
  margin-top: 0px;
  margin-bottom: 0px;
}

#postern-provenance #legal-disclaimer {
  margin-bottom: 50px;
}

.postern-section-summary-paragraph {
  padding: 6px 15px;
}

.m-word-break{
    word-break: break-all;
}

.m-mar-a{
    margin: auto;
}

.m-ital{
    font-style: italic;
}

.provenance-table .row-padding{
    padding: 6px 15px;
}

.grey_link, .grey_link:focus, .grey_link:hover {
    letter-spacing: 0;
    color: var(--grey);
    text-decoration: none!important;
    cursor: pointer;
}
  

@media print{
    .print-hide{
        display: none;
        height: 0;
    }
}