#layout-base {
  margin-bottom: 45px;
  position: relative;
}

#layout-base .header-logo {
  max-width: fit-content;
}

#layout-base .name-logout {
  max-width: fit-content;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}

#guardian-nav {
  background: #1d4b68;
  display: flex;
  border-bottom: 0.5px solid grey;
  box-shadow: 0px 6px 3px -4px grey;
  padding: 5px 0;
  align-items: center;
}

#guardian-nav > div {
  flex: 1;
}

.layout-info-element {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 5px;
}
