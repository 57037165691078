#provenance-cyber-map {
    min-height: 400px;
    height: 70vh;
    max-height: 800px;
    padding: 20px 0;
}

#provenance-sga-overview h5{
    margin: 0px;
}

.cybermap-legend-color{
    padding: 2px 10px;
}

.cybermap-legend-text{
    padding-right: 25px;
}

.provenance-cybermap-marker-legend{
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.provenance-cybermap-marker-legend div{
  margin-right: 10px;
}

.provenance-cybermap-country-legend{
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
}

#provenance-sga-overview thead tr th:nth-child(1){
    text-align: left;
}

#provenance-sga-overview thead tr th{
    text-align: center;
}

#provenance-sga-overview .row-padding{
    padding: 8px 15px;
}