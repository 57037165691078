.file-access-request-modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1300px;
    height: 625px;
}

.file-access-request-modal .header {
    display: flex; 
}

.file-access-request-modal h5 {
    text-align: center;
    width: 95%;
}

.file-requests-title {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

.file-requests-column-container {
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: 400px;
    display: flex;
    flex-direction: column;
}

.file-requests-list {
    padding: 8px;
    transition: background-color 0.2s ease;
    background-color: white;
    min-height: 450px;
    max-height: 450px;
    overflow-y: scroll;
}

.file-requests-list.isdraggingover {
    background-color: #e3f7ff;
}

.file-access-request-groups {
    display: flex;
}

.file-access-request .companyLogo {
    max-width: 20%;
    margin-right: 10px;
    height: 70px;
}

.file-access-request .requestContent {
    display: flex;
}

.file-access-request .buttonRow {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.file-access-request .contentInfo {
    width: 100%;
}

.file-access-request-container {
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 8px;
    background-color: white;
    padding: 10px;
}

.file-access-request-container.isdragging {
    background-color: #e3ffe8;
    top: auto !important;
    left: auto !important;
}

.view-nda-lnk {
    border-radius: 5px;
    border: 0;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
}

.file-requests-list[data-rbd-droppable-id="access-granted"] .file-access-request-container {
    background: #d7f2ed;
}


.file-requests-list[data-rbd-droppable-id="access-denied"] .file-access-request-container {
    background: #ffcaca;
}

.mini-busy {
    position: relative;
}

.sa-modal-close .mini-busy .fa-spin {
    zoom: .2;
    bottom: -20px;
    right: 20px;
}

.mini-busy .fa-spin {
    zoom: .3;
    position: absolute;
    bottom: -33px;
    right: 125px;
}