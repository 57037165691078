.page-container {
  width: 100%;
  padding: 0 5em;
  margin-top: -20px;
}

.product-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.version-wrapper {
  display: flex;
  width: 22%;
}

.version-label {
  color: black;
  font-weight: bold;
  display: inline-block;
  margin: 8px 20px 0 50px;
}

.version-select {
  margin-top: -8px;
  width: 350px;
}

.overview-no-cmre-data-notice {
  width: 100%;
}

.overview-head {
  display: flex;
  justify-content: space-between;
  width: 78%;
}

.overview-wrapper {
  display: flex;
  width: 100%;
  max-height: 100px;
  align-items: flex-end;
  margin-bottom: -1px;
}

.overview-label {
  color: black;
  font-weight: bold;
  display: inline-block;
  font-size: 1.3vw;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 200px;
}

.overview-content {
  display: flex;
  flex: 1;
}

.overview-content > div:first-child {
  width: 25%;
  margin-right: 15px;
}

.overview-content > div:last-child {
  width: 75%;
}

.overview-data-display {
  background: #e8ebf0;
  padding-bottom: 5px;
}

.overview-data-display > div:not(.overall-content) {
  margin: 10px 15px;
}

.overall-content {
  display: flex;
  flex: 1;
}

.overall-content > div:first-child {
  width: 36%;
  min-width: 425px;
  margin: 10px 15px;
}

.overall-content > div:last-child {
  width: 64%;
  margin: 10px 15px;
}

.display-card {
  border-radius: 5px;
  box-shadow: 0px 5px 8px #c1c1c1;
  display: flex;
  flex-direction: column;
}

.display-card-header {
  background-color: #f7f7f7;
  color: #8791af;
  border: 1px solid #e8e8e8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 22px;
  padding: 15px 10px;
  text-align: center;
}

.display-card-content {
  border: 1px solid #e8e8e8;
  border-top: 0;
  background: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 20px 35px;
  flex: 1;
}

.overall-scores-by-cat .display-card-content {
  padding: 0;
}

.display-card-content .card-body {
  padding: 0;
  position: relative;
  top: 25px;
}

.display-json-content {
  border: 1px solid #e8e8e8;
  border-top: 0;
  background: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  max-height: 400px;
  overflow: auto;
  padding: 10px 20px;
  white-space: pre;
}

.display-json-content.no-json {
  text-align: center;
  padding: 25px;
}

.product-info {
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.overall-summary {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.overall-summary > div {
  width: 30%;
  border: 1px solid #075269;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  color: #075269;
  padding: 3px;
  font-size: 18px;
}

.overall-summary.score-cat-high > div {
  border-color: #ff6600;
}

.score-cat-high .overall-severity-score {
  background: #ff6600;
  color: white;
}

.overall-summary.score-cat-critical > div {
  border-color: #ff0000;
}
.score-cat-critical .overall-severity-score {
  background: #ff0000;
  color: white;
}

.overall-summary.score-cat-moderate > div {
  border-color: #f7c341;
}

.score-cat-moderate .overall-severity-score {
  background: #f7c341;
}

.overall-summary.score-cat-low > div {
  border-color: #014f6e;
}

.score-cat-low .overall-severity-score {
  background: #014f6e;
  color: white;
}

.overall-summary.score-cat-na > div {
  border-color: #808080;
}

.score-cat-na .overall-severity-score {
  background: #808080;
  color: white;
}

.overall-score-breakdown {
  margin-top: 10px;
}

.overall-score-breakdown table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.overall-score-breakdown tr {
  border-top: 1px solid #e7eaed;
  border-bottom: 1px solid #e7eaed;
}

.overall-score-breakdown td,
.overall-score-breakdown th {
  padding: 15px;
  font-weight: bold;
}

.overall-score-breakdown th:nth-child(2) {
  min-width: 130px;
}

td.no-scores-avail {
  font-weight: normal;
}

.overall-score-breakdown tbody tr:nth-child(2n-1) {
  background: #f2f2f2;
}

.page-container .product-assessment-reports {
  background: #f6f6f6;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 8px #c1c1c1;
}

.product-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

img.product-info-vendor-logo {
  max-height: 50px;
  max-width: 8%;
  padding-right: 20px;
}

.product-info-product-description-wrapper {
  display: inline-block;
  width: 92%;
}

.product-info-vendor-name {
  margin-top: 1rem;
}

.product-info-product-description-line {
  margin: 15px 25px 0 25px;
  color: gainsboro;
  font-weight: bold;
}

.product-info-sub-label {
  font-weight: bold;
  margin-right: 10px;
}

.product-assessment-reports-table {
  width: 100%;
  border-collapse: collapse;
}

.product-assessment-reports-table .no-reports {
  text-align: center;
  padding-top: 10px;
}

.product-assessment-reports-table tr td:first-child {
  padding-left: 10px;
  width: 40%;
}

.product-assessment-reports-table tr th:nth-child(3) {
  text-align: center;
}

.product-assessment-reports-table tr td:nth-child(3) {
  width: 15%;
  text-align: center;
}

.product-assessment-reports-table tr td:last-child {
  width: 20%;
  text-align: center;
}

.product-assessment-reports-table tr td:last-child div {
  margin: 0 !important;
}

.product-assessment-reports-table tr td:last-child .fa-spin {
  zoom: 0.25;
  position: relative;
  top: 1px;
}

.blue-report-btn {
  background: #014f6e;
  border: 1px solid #014f6e;
  border-radius: 5px;
  color: white;
  width: 145px;
  padding: 5px;
}

.blue-report-btn:hover {
  background: #003852;
  border-color: #003852;
}

.orange-report-btn {
  background: #ff9900;
  border: 1px solid #ff9900;
  border-radius: 5px;
  color: white;
  width: 145px;
  padding: 5px;
}

.orange-report-btn:hover {
  background: #de8602;
  border-color: #de8602;
}

.gray-report-btn {
  background: #696969;
  border: 1px solid #696969;
  border-radius: 5px;
  color: white;
  width: 145px;
  padding: 5px;
}

.gray-report-btn:hover {
  background: #696969;
  border-color: #696969;
}

.product-checkout-button-container {
  margin-top: 20px;
}

.product-checkout-button-container .modal-report-info-btn {
  position: unset;
}

.product-assessment-reports-table .order-other-reports-btn {
  text-align: center;
  padding-top: 15px;
}

.product-order-reports-result-modal {
  height: fit-content;
  height: -moz-fit-content;
  min-height: unset;
  padding-bottom: 20px;
  font-size: 20px;
}

.product-order-reports-result-modal-close-btn {
  margin: auto;
  margin-top: 20px;
  display: block;
  position: unset;
}

#product-modal-reports-table th {
  font-size: 18px;
}

.btn.btn-view-more,
.btn.btn-view-less {
  color: #014f6e;
  font-weight: bold;
  font-size: 12px;
  padding: 0;
  margin: 0 0 0 10px;
}

.btn.btn-view-more {
  margin-bottom: 14px;
}

.btn.btn-view-more:focus,
.btn.btn-view-less:focus {
  box-shadow: none;
}

.fas.fa-clock,
.fas.fa-bolt {
  height: fit-content;
  margin-top: 2px;
}

.tooltip-on-hover-report {
  max-width: 40%;
  width: fit-content;
}

.tooltip-on-hover-report-icon {
  max-width: 15%;
  width: fit-content;
}

.product-info-container {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-bottom: 10px;
}

.container-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container-row div:nth-child(n) {
  padding-left: 10px;
  padding-right: 10px;
}

.product-info-logo {
  height: 55px;
  width: 69px;
  border-radius: 8px;
}

.id-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.id-row div:not(:last-child) {
  border-right: 1px solid #ccc9c9;
}

.expand-contributors {
  margin-left: 10px;
  color: #014f6e;
}

.expand-contributors:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}
