#risk-explanation {
  font-weight: lighter;
  margin: 20px 0 0;
  font-size: smaller;
  font-style: italic;
  color: #212529;
}

.overall-vendor-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.compliance-alerts {
  margin-top: 10px;
  padding: 10px;
}

.compliance-alerts-header {
  font-weight: 550;
}

.is-cip-13-button {
  margin-bottom: 10px;
}

.executive-order-status {
  display: flex;
  justify-content: center;
}
