/*********************
* Guardian CSS
*********************/

/* #catalog-modal-stage-2 .modal-report-generic-info{
    left: -25px;
} */

#catalog-modal-stage-4 a {
  width: fit-content;
  text-decoration: none;
}
#catalog-modal-stage-4 a:hover {
  color: white;
}

.product-report-box .m-soft-bor-bot:nth-last-child(1) {
  border-bottom: 0px solid #ced4da;
}

.m-txt-size-m {
  font-size: 16px;
}

.m-soft-bor-bot {
  border-bottom: 1px solid #ced4da;
}

.product-report-box.file-box {
  min-height: 64px;
  max-height: 128px;
}

.dropzone-outline {
  border: 1px solid black;
  border-style: dashed;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px 0 25px 0;
}

.dropzone-outline.extended {
  padding: 20px 0 40px 0;
}

.catalog-drop-zone {
  border: 1px solid #ced4da;
  max-width: 500px;
  padding: 20px;
}

.dropzone-with-button {
  margin: auto;
  cursor: pointer;
}

.dropzone-with-button .dropzone-outline {
  padding: 20px 80px;
}

.dropzone-with-button button {
  width: 98px;
  height: 25px;
  border: 1px solid black;
  border-radius: 3.2px;
  background-color: white;
}

.upload-logo-box,
.upload-helper-text {
  height: 65%;
  text-align: center;
  cursor: pointer;
}

.upload-logo-box .dropzone-outline,
.upload-helper-text .dropzone-outline {
  height: 100%;
}

.upload-file-accepted {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.upload-file-rejected {
  color: red;
}

.fa.fa-trash.red {
  color: red;
  font-size: 1vh;
}

#catalog-domain-error {
  width: 250px;
}

.no-border {
  border: none !important;
}

.m-error {
  margin: auto;
  margin-top: 5px;
  width: fit-content;
  border-left: 3px solid red;
  padding-left: 5px;
  font-weight: 500;
  font-size: 15px;
}

.m-cancel-cursor {
  cursor: unset !important;
}

.remove-product-report {
  padding: 0px 5px !important;
  font-size: 12px !important;
  vertical-align: text-bottom !important;
}

.product-report-box {
  border: 1px solid #ced4da;
  overflow-x: hidden;
  min-height: 120px;
  max-height: 160px;
}

.modal-s-2-input {
  max-width: 300px;
}

.add-requested-btn {
  margin: 20px 0;
  background: #d8b32c;
  color: #fff;
}

#catalog-modal-stage-2 .modal-report-info {
  text-align: left;
  margin-bottom: 4px;
}

#catalog-modal-stage-2 .modal-vendor-logo-box {
  height: 80px;
}

.input-title-text {
  text-align: left;
  font-weight: 700;
  font-size: 18px;
}

.m-flex-out {
  display: flex;
  justify-content: space-between;
}

.m-flex-around {
  display: flex;
  justify-content: space-around;
}

.m-flex-start {
  display: flex;
  justify-content: flex-start;
}

.add-vendor-error {
  text-align: center;
  font-size: 14px;
  padding: 10px 10px 0 10px;
  color: red;
}

.order-total-text {
  text-align: right;
  padding-right: 20px;
}

#catalog-modal-stage-4 .modal-report-info-btn,
#catalog-modal-stage-3 .modal-report-info-btn,
#catalog-modal-stage-2 .modal-report-info-btn {
  margin: auto;
  margin-top: 40px;
  display: block;
  position: unset;
}

#catalog-modal-stage-4,
#catalog-modal-stage-3,
#catalog-modal-stage-2,
#add-vendor-top-modal {
  height: fit-content;
  height: -moz-fit-content;
  min-height: unset;
  padding-bottom: 20px;
  font-size: 20px;
}

#add-vendor-top-modal {
  padding-top: 20px;
}

.modal-mt-override {
  margin-top: 0px !important;
}

/* Add vendor modal start */

#modal-m-add .modal-vendor-info-right-footnote {
  display: none;
}

#modal-m-add .modal-vendor-info-box {
  height: 162px;
}

/* Add vendor modal end */

.catalog-page .paginate-holder .btn.btn-primary {
  background-color: #718f9c;
}

.catalog-page .paginate-holder .btn.btn-primary:hover {
  background-color: #43545c;
}

.modal-NAICS {
  width: calc(100% - 65px);
}

.lg-icon-fa {
  font-size: 25px;
}

.catalog-page {
  margin-left: 20px;
}

.vendor-list,
.product-list {
  flex: 1;
  margin-left: 36px;
}

input.form-control.catalog-search-input {
  width: 260px;
}

.catalog-search-filters-form {
  width: 100%;
  background-color: gainsboro;
  display: inline-block;
  max-height: 800px;
  padding-bottom: 10px;
}

.catalog-search-filters-div {
  background-color: white;
  color: gray;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  position: relative;
}

.catalog-search-filters-icon {
  margin-top: 11px;
  margin-left: 12px;
  position: absolute;
}

.catalog-search-filters-input {
  border: none;
  padding-left: 39px;
}

.catalog-search-filters-checkboxes-form {
  margin-left: 30px;
  margin-bottom: 5px;
  display: flex;
}

.catalog-search-filters-checkboxes-sections-title {
  font-weight: bold;
  margin: 12px 20px 10px 20px;
}

.catalog-search-filters-checkboxes-input {
  margin-right: 9px;
  display: flex;
}

.catalog-search-filters-checkboxes-label {
  font-size: 10px;
  margin-bottom: 2px;
  display: flex;
}

.catalog-list-cards-div {
  margin-bottom: 40px;
}

.catalog-my-vendor-switch-btn-label {
  font-size: large;
  font-weight: 600;
  margin-top: 2px;
  margin-left: 5px;
}

.add-vendor-cricle {
  background-color: green;
}

.paginate-holder {
  margin: auto;
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 40px;
}

.catalog-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 18px;
  margin-right: 50px;
}

.add-vendor-button {
  margin-right: 30px;
}

.vendor-text {
  font-weight: 500;
  padding-top: 12px;
  margin-top: -7px;
  text-align: center;
}

.add-vendor-button:hover .vendor-cricle {
  opacity: 0.7;
}

.add-vendor-button:hover .vendor-text {
  color: rgb(248, 172, 89);
}

.catalog-mini-nav-area {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-nav-switch {
  white-space: nowrap;
}

.catalog-view-option {
  display: inline-block;
  padding: 10px 20px;
  color: black;
  background: #eeeeee;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #888888;
  font-weight: bold;
  box-shadow: inset 0px 0px 5px #888888;
}

.catalog-view-option:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.catalog-view-option:last-child {
  border-right-width: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.catalog-view-option.selected {
  border-color: #014f6e;
  background: #014f6e;
  color: white;
  box-shadow: 0 0 2px black;
}

.catalog-view-option:hover {
  text-decoration: none;
}

.catalog-view-option:not(.selected):hover {
  background: #15719b;
  border-color: #15719b;
  color: white;
  box-shadow: 0 0 2px black;
}

.modal-my-vendors-button {
  margin-right: 10px !important;
  width: 85px !important;
}

.catalog-sidebar .input-group {
  flex-wrap: nowrap;
  position: relative;
}

/*********************
* Add Vendor Card CSS
*********************/

.filter-search-area {
  position: absolute;
  top: 52px;
  right: 20px;
}

.filter-search-text {
  margin: 0;
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.selected-tab {
  border-style: solid;
  border-color: #b8d3e0;
  border-width: 2px 2px 0px 2px;
  border-radius: 10px 10px 0px 0px;
}

.unselected-tab {
  border-style: solid;
  border-color: #fff;
  border-width: 2px 2px 0px 2px;
  border-radius: 10px 10px 0px 0px;
}

.allow-button-space {
  width: calc(100% - 80px);
}

.modal-vendor-icons {
  position: absolute;
  top: 10px;
  right: 5px;
}

.modal-icon-text {
  font-size: 12px;
  width: fit-content;
  text-align: center;
  margin: auto;
}

.sm-icon-tag {
  width: 25px !important;
  height: 25px !important;
  font-weight: 700;
  text-align: center;
  border-radius: 100px;
}

.sm-icon-fa {
  font-size: 14px !important;
  line-height: 25px;
}

.lg-icon-fa {
  font-size: 25px;
}

.catalog-buttons-box {
  display: flex;
  width: calc(100% - 210px);
}

.catalog-button {
  padding: 0 10px;
}

.add-vendor-color {
  background: green;
  color: white;
}

.my-vendors-color {
  color: white;
  background: #002280;
}

.not-my-vendors-color {
  color: white;
  background: gray;
}

.modal-my-vendors-button:hover .my-vendors-color {
  background: gray;
}

.modal-my-vendors-button:hover .not-my-vendors-color {
  background: #002280;
}

.modal-my-vendors-button:hover .modal-icon-text {
  color: rgb(248, 172, 89);
}

.vendor-cricle {
  font-size: 30px;
  width: 44px;
  display: block;
  font-weight: 700;
  text-align: center;
  border-radius: 100px;
  margin: auto;
  color: #fff;
}

.add-vendor-button {
  width: 94px;
  padding-top: 10px;
  cursor: pointer;
}

#add-vendor,
#add-vendor-website {
  margin: auto;
  width: 250px;
}

.modal-add-vendor-name {
  font-size: 40px;
  line-height: 45px;
}

.modal-add-vendor-title {
  font-size: 30px;
}

/*********************
* endless-scroll CSS
*********************/

#endless-scroll-wrapper {
  width: 100vw;
  max-width: 1868px;
  /* height: calc(100vh - 180px); */ /* test */
  height: calc(100vh - 122px);
  overflow: auto;
  border-style: solid;
  border-color: #b8d3e0;
  border-width: 2px;
}

#endless-scroll-content {
  display: flex;
  flex-wrap: wrap;
}

/*********************
* modal CSS
*********************/

.temp-space {
  height: 24px;
}

.modal-report-info {
  cursor: pointer;
  margin-bottom: 8px;
  position: relative;
}

.modal-report-info:hover {
  background: #d7f2ed;
}

.modal-report-info .fas {
  margin-left: 5px;
}

.modal-report-trustcenter {
  padding-left: 22px;
}

.color-item {
  padding: 2px 5px;
  border-radius: 5px;
  background: #86e2ee;
  text-transform: capitalize;
}

.high {
  background-color: rgb(231, 50, 50);
  color: #fff;
}

.critical {
  background-color: rgb(231, 50, 50);
  color: #fff;
}

.low {
  background-color: rgb(11, 153, 11);
  color: #fff;
}

.moderate {
  background-color: #e0d22a;
  color: #fff;
}

.m-bold {
  font-weight: 700;
}

.m-tal-c {
  text-align: center;
}

.indicators-of-risk {
  display: flex;
  flex-wrap: wrap-reverse;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

#modal-m-vendor,
#modal-m-product {
  position: relative;
  margin: 10vh auto;
  background: #f5f6f8;
  overflow: scroll;
  overflow-x: hidden;
  width: 60vw;
  max-height: 80vh;
  padding: 10px;
  box-shadow: 0 5px 50px -4px #aaa;
  border-radius: 8px;
  max-width: 1250px;
  min-width: 750px;
}

#modal-m-vendor span.modal-close {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 20px;
  top: 15px;
}

#modal-m-vendor p,
#modal-m-add p {
  margin-bottom: 0;
}

.modal-vendor-info-box {
  position: relative;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  height: 230px;
}

.modal-vendor-info-left {
  position: absolute;
  width: 40%;
  top: 20px;
}

.modal-vendor-info-right-footnote {
  position: absolute;
  right: 60px;
  bottom: 0;
}

.modal-vendor-info-right {
  position: absolute;
  left: 40%;
  top: 20px;
  width: 60%;
  height: 205px;
}

.modal-vendor-logo-box {
  text-align: center;
  max-width: 90%;
  height: 90px;
}

.modal-vendor-logo {
  max-width: 90%;
  max-height: 90px;
}

.modal-vendor-name {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 1rem;
  max-width: 90%;
  height: 110px;
}

.modal-vendor-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  line-height: 30px;
}

.modal-vendor-overview-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.modal-vendor-overview-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 150px;
  background: #fff;
  border: 1px solid grey;
  border-radius: 20px;
  color: #000;
  margin-top: 15px;
  font-size: 15px;
}

a.modal-vendor-overview-button:hover {
  border: none;
  text-decoration: none;
}

.modal-predicted-risk-tier {
  text-align: center;
  max-width: 90%;
}

.modal-report-info-box {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
  background: #fff;
  min-height: 325px;
}

.modal-vendor-info {
  max-height: 320px;
  overflow: hidden;
  padding-right: 20px;
}

.modal-report-info-title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.indicators-of-risk {
  font-size: 12px;
}

.risk-type {
  margin-top: 4px;
  width: 47%;
  padding-right: 7%;
}

.risk-title {
  font-weight: 525;
}

.risk-type .color-item {
  float: right;
}

.modal-report-info-description {
  text-align: center;
  width: 70%;
  min-width: 600px;
  margin: auto;
  font-size: 18px;
}

.modal-report-info-list {
  margin-top: 40px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-around;
}

.modal-report-table {
  margin: auto;
  margin-top: 15px;
  width: 90%;
  text-align: center;
  line-height: 2;
}

.modal-report-table tr:hover td {
  background-color: #d7f2ed;
}

.modal-report-column-reports {
  width: 35%;
}

.modal-report-column-dates {
  width: 25%;
}

.modal-report-column-shares {
  width: 25%;
}

.modal-report-column-prices {
  width: 15%;
}

.modal-report-title {
  display: inline-block;
  position: relative;
  padding-left: 7px;
  font-size: 15px;
}

.report-section-title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 10px;
}

.modal-report-generic-info {
  cursor: text;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 8px;
  min-width: 350px;
  max-width: 55vw;
  /* width: 475px; */
  z-index: 2;
  display: none;
  text-align: left;
  position: absolute;
  left: 100%;
  bottom: 50%;
  transform: translateY(50%);
}

.modal-report-generic-info {
  border: 2px solid #08af8f;
}

.modal-report-info-list .modal-report-title {
  position: unset;
}

.asset-reports-list .modal-report-generic-info {
  left: unset;
  right: 100%;
}

.modal-report-title:hover .modal-report-generic-info,
.modal-report-generic-info:hover,
.modal-report-info:hover .modal-report-generic-info {
  display: block;
}

.modal-report-generic-info-title {
  display: flex;
  white-space: nowrap;
  font-weight: 700;
  line-height: 12px;
  font-size: 12px;
  margin: 0;
  padding-bottom: 3px;
}

.modal-report-generic-last-purchase {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  flex: 1;
  text-align: right;
  white-space: nowrap;
  padding-left: 20px;
}

.modal-report-generic-info-description {
  line-height: 14px;
  font-size: 12px;
  overflow: hidden;
  margin: 0;
}

.modal-report-generic-info-link {
  line-height: 12px;
  font-size: 12px;
  height: 12px;
  overflow: hidden;
  cursor: pointer;
}

.modal-report-info-btn {
  position: absolute;
  background: #2ece2e;
  color: #fff;
  bottom: 20px;
  right: 20px;
}

.modal-report-info-btn-stage1 {
  background: #2ece2e;
  color: #fff;
}

.modal-report-bottom-right {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.modal-report-info-btn:hover {
  opacity: 0.7;
}

.modal-report-request-error {
  text-align: center;
  position: relative;
  color: red;
}

.modal-report-request-resolve {
  text-align: center;
  padding-top: 10px;
}

.add-vendor-error > strong,
.modal-report-request-resolve > strong {
  color: black;
}

.modal-report-info-email-note {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.modal-report-tick-box {
  cursor: pointer;
  height: 14px;
  width: 14px;
  position: relative;
  left: 3px;
  top: 1px;
}

.modal-report-sharing-propensity-text {
  text-align: left;
  font-size: 10px;
  color: blue;
}

/*********************
* CatalogueAR CSS
*********************/

.quick-space {
  margin-left: 19px !important;
}

.main-content {
  padding: 20px;
}

.vendor-logo-box {
  height: 60px;
}

.product-logo-box {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-logo-box img {
  max-height: 100px;
  max-width: 250px;
}

.vendor-logo {
  max-width: 140px;
  max-height: 60px;
}

.vendor-cata-card-list {
  display: flex;
  flex-wrap: wrap;
}

.vendor-cata-card-icons {
  display: flex;
  justify-content: space-around;
  height: 30px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

button.vendor-cata-card-button-order,
a.vendor-cata-card-button-info {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  color: #000;
  background-color: #fff;
  border: 1px solid #43545c;
  border-radius: 20px;
  margin: 10px 0px;
  font-size: 0.75rem;
}
button.vendor-cata-card-button-order:hover,
a.vendor-cata-card-button-info:hover {
  text-decoration: underline;
  border: none;
}

.vendor-cata-card,
.product-cata-card {
  cursor: pointer;
  text-align: center;
  width: 185px;
  height: 287px;
  margin: 10px;
  border: 1px solid #d4d4d4d4;
  box-shadow: 2px 4px 10px 5px #e4e4e4;
  padding: 10px;
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px;
  border-image: none;
  border-color: #e7eaec;
  border-style: solid solid none;
  border-width: 1px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-left: 5px groove #f5f5f5;
  border-radius: 4px;
}

div.product-cata-card {
  width: 275px;
  height: 350px;
}

.vendor-cata-card-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #3c3f44;
}

.vendor-cata-card-url:hover {
  font-weight: bold;
  color: #002280;
}

.vendor-cata-card:hover {
  box-shadow: 0 3px 6px rgba(37, 99, 117, 0.16), 0 3px 6px rgba(37, 99, 117, 0.16);
}

.vendor-cata-card-title {
  margin-top: 11px;
  margin-bottom: 5px;
  line-height: 1.05em;
  height: 3.15em;
  overflow: hidden;
  font-size: 0.85em;
  font-weight: 700;
  text-align: center;
  word-break: break-word;
}

.product-cata-card-title {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  word-break: break-word;
}

.vendor-cata-card-buttons-list > a:hover,
.vendor-cata-card-buttons-list > button:hover {
  text-decoration: none;
}

.vendor-cata-card .vendor-cata-card-buttons-list {
  display: none;
}

.vendor-cata-card:hover .vendor-cata-card-buttons-list {
  display: inherit;
}

.vendor-cata-card-report-icon {
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  background: rgb(146, 146, 146);
  margin-right: 10px;
}

.vendor-cata-card-report-title {
  font-size: 0.75em;
  font-weight: 600;
  margin: 0;
}

.product-cata-card-versions {
  height: 23px;
  margin-bottom: 10px;
}

.product-cata-card-ver-num {
  font-weight: bold;
  color: #014f6e;
}

.product-cata-card-description {
  max-height: 135px;
  word-break: break-word;
  overflow-y: auto;
}

.search-vendors {
  /* margin-bottom: 16px;
    width: calc(100% - 140px); */
  width: 200px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  font-size: 14px;
  box-shadow: none;
  line-height: 1.5;
}
.search-vendors:focus {
  border-color: #1ab394 !important;
  outline: 0;
}

/*********************
* Scroll Bar
*********************/

::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #b8d3e0;
}

::-webkit-scrollbar-thumb {
  background: #718f9c;
}

::-webkit-scrollbar-thumb:hover {
  background: #006c9e;
}

/*********************
* Catalog Display
*********************/
.catalog-content {
  display: flex;
}

.catalog-sidebar-wrapper {
  width: 304px;
  height: fit-content;
  display: inline-block;
  overflow: auto;
  position: sticky;
  top: 10px;
  margin-top: -2px;
}

.catalog-sidebar {
  width: 300px;
}

.search-icon {
  margin-top: 11px;
  margin-left: 12px;
  position: absolute;
}

#filterOptions {
  border: none;
  padding-left: 39px;
}

.show-my-vendors-container {
  height: 30px;
  display: flex;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 15px;
  padding-right: 25px;
}

.show-my-vendors-container > div {
  display: flex;
}

.my-vendors-toggle {
  width: 60px;
  position: relative;
}

.my-vendors-toggle .fa-spin {
  zoom: 0.3;
  position: absolute;
  top: 10px;
}
