.p-ddrr-vendor-logo {
  padding-top: 10px;
  max-width: 100px;
  max-height: 100px;
}

.p-ddrr-industry {
  color: #1ab394;
}

.p-ddrr-vendor-text {
  font-size: 11pt;
  line-height: 1.25;

}
