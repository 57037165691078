#db-container h3,
#db-container h4 {
  font-weight: bold;
}

/* -------------------- */
/*        tiles         */
/* -------------------- */

.db-tile-flex-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.db-t-wrapper {
  width: 20%;
  box-shadow: 0 3px 6px rgba(56, 56, 56, 0.16), 0 3px 6px rgba(56, 56, 56, 0.16);
  border-radius: 5px;
  padding: 10px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dtg-large {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
}

.dtg-medium {
  display: none;
}

.dtg-small {
  display: none;
}

.db-t-coming-soon {
  background-color: rgba(0, 0, 0, 0.75);
}

.coming-soon-text {
  color: aqua;
  font-size: small;
  margin-bottom: 31px;
}

@media only screen and (max-width: 1350px) {
  .dtg-large,
  .dtg-small {
    display: none;
  }

  .dtg-medium {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dtg-sub-medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .db-t-wrapper {
    margin-bottom: 30px;
    width: 40%;
  }
}

@media only screen and (max-width: 750px) {
  .dtg-large,
  .dtg-medium,
  .dtg-sub-medium {
    display: none;
  }

  .dtg-small {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .db-t-wrapper {
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 432px) {
  .db-t-wrapper {
    width: 290px;
  }
}

/* -------------------- */
/*      my vendors      */
/* -------------------- */

.db-vendors-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.db-vendors-map,
.db-vendors-table {
  width: 800px;
  height: 560px;
}

.db-vendors-table-without-map {
  width: 100%;
  height: fit-content;
}

/* -------------------- */
/*   tokens & orders    */
/* -------------------- */

#db-container hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border-color: #b7b7b7;
}

.db-tokens-orders-flex-container-large {
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
}

.db-tokens-orders-flex-container-medium {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.db-spend-breakdown {
  width: 600px;
  height: 480px;
  box-shadow: 0 3px 6px rgba(56, 56, 56, 0.16), 0 3px 6px rgba(56, 56, 56, 0.16);
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.db-spend-breakdown-medium {
  width: 100%;
  height: 500px;
  box-shadow: 0 3px 6px rgba(56, 56, 56, 0.16), 0 3px 6px rgba(56, 56, 56, 0.16);
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.db-orders-table {
  width: 1250px;
  /* height: 450px; */
  margin-bottom: 90px;
}

.db-orders-table-medium {
  width: 100%;
  /* height: 450px; */
  margin-top: 20px;
  margin-bottom: 90px;
}

.db-sb-balance-chart-fc {
  display: flex;
  height: 450px;
  margin-top: -30px;
}

.db-sb-balance-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

#db-sb-chart {
  height: 450px;
  width: 400px;
}

#db-sb-chart-mobile {
  height: 480px;
  width: 800px;
}

.db-sb-wrapper {
  margin-bottom: 20px;
}

.db-csv-wrapper {
  display: flex;
  justify-content: flex-end;
}

#csv-orders-link {
  padding: 4px 6px;
  display: block;
  width: fit-content;
  background: #004e71;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

#vendor-import-btn {
  background: #004E71; 
  color: white; 
}

#vendor-import-btn:hover {
  background: #003852
}

.my-vendors-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 2050px) {
  .db-orders-table {
    width: 1500px;
  }
}
