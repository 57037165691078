#cyber-scanner-map .severity-flag{
    display: inline-block;
    padding-right: 5px;
}

.cyber-flag-data{
    padding-bottom: 2px;
}

#ddrr-cyber-map{
    min-height: 400px;
    height: 70vh;
    max-height: 800px;
    padding: 20px 0;
}