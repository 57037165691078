.claim-page-btn {
  color: white;
  border: none;
  outline: none;
}

.modal-main-label {
  text-align: center;
}

.modal-label {
  margin-bottom: 0px;
}

.modal-textarea-div {
  padding-bottom: 0px;
}

.modal-textarea {
  width: 100%;
}

.modal-cancel-btn {
  margin-right: 15px;
}
