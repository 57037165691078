.p-max-height{
    height: 80vh;
}

/* @media print {
    
    .p-max-height{
        display: block;
        height: fit-content;
    }
} */