@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: url('OpenSans-Bold.ttf');
}

body {
  font-family: 'Open Sans';
}

hr {
  background-color: #808080;
}

.link-button {
  background-color: transparent;
  color: #18c2bd;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.hide-component {
  display: none;
}

.link-button:hover {
  color: #0f7864;
}

.link-button:focus {
  outline: none;
}

.og-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.og-alert-heading {
  color: inherit;
}

.og-alert-link {
  font-weight: 700;
}

.og-alert-dismissible {
  padding-right: 4rem;
}

.og-alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.og-alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.og-alert-primary hr {
  border-top-color: #9fcdff;
}

.og-alert-primary .og-alert-link {
  color: #002752;
}

.og-alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.og-alert-secondary hr {
  border-top-color: #c8cbcf;
}

.og-alert-secondary .og-alert-link {
  color: #202326;
}

.og-alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.og-alert-success hr {
  border-top-color: #b1dfbb;
}

.og-alert-success .og-alert-link {
  color: #0b2e13;
}

.og-alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.og-alert-info hr {
  border-top-color: #abdde5;
}

.og-alert-info .og-alert-link {
  color: #062c33;
}

.og-alert-bad {
  color: #856404;
  background-color: #ffffff;
  border-color: #ff0000;
}

.og-alert-warning {
  color: #856404;
  background-color: #ffffff;
  border-color: #ffeeba;
}

.og-alert-warning hr,
.og-alert-bad hr {
  border-top-color: #ffe8a1;
}

.og-alert-warning .og-alert-link {
  color: #533f03;
}

.og-alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.og-alert-danger hr {
  border-top-color: #f1b0b7;
}

.og-alert-danger .og-alert-link {
  color: #491217;
}

.og-alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.og-alert-light hr {
  border-top-color: #ececf6;
}

.og-alert-light .og-alert-link {
  color: #686868;
}

.og-alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.og-alert-dark hr {
  border-top-color: #b9bbbe;
}

.og-alert-dark .og-alert-link {
  color: #040505;
}

.alert-bar {
  background-color: #e74c3c;
  color: white;
}

.alert-bar i {
  padding: 5px 10px 0 15px;
  color: white;
}

#container {
  color: #999;
  font-size: 16px;
  font-weight: 500;
  width: 85%;
  display: block;
}

#flip {
  height: 25px;
  overflow: hidden;
}

#flip > div > div {
  color: #fff;
  height: 45px;
  margin-bottom: 45px;
  display: inline-block;
}

#flip div:first-child {
  animation: show 30s linear infinite;
}

#flip div div {
  color: white;
}
#flip div:first-child div {
  color: white;
}
#flip div:last-child div {
  color: white;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

.options-collapsed .mobile-margin {
  margin: 7px 22px !important;
}

.options-collapsed #dl-menu #navbarCollapse ul {
  flex-direction: column;
}

.options-collapsed .dl-menu .nav-item {
  width: 100%;
  border: 1px solid #e7e7e7;
  border-bottom: 0;
}

.options-collapsed .dl-menu .nav-item:last-child {
  border-bottom: 1px solid #e7e7e7;
}

.options-collapsed .nav-sub .nav-item {
  width: 100%;
}

.options-collapsed .nav-link {
  background-color: white;
  color: #2c3e50 !important;
  border-color: #e7e7e7;
}

.options-collapsed .nav-link:hover {
  background-color: #e7e7e7;
}

.options-collapsed .dl-submenu {
  position: inherit !important;
  float: none !important;
  border-radius: 0;
  margin: -1px !important;
  padding-left: 15px;
  padding-top: 0;
  border-top: 0;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
}

.options-collapsed .dl-submenu a:hover,
.options-collapsed .dl-submenu button:hover {
  background-color: #e7e7e7;
}

.options-collapsed .dl-submenu .dropdown-menu {
  position: inherit !important;
  display: block !important;
}

.options-collapsed .header-logo {
  text-align: center;
}

.options-collapsed .name-logout {
  position: absolute;
  right: 0;
}

.options-collapsed #dl-menu #navbarCollapse ul .nav-item a:not(.dropdown-item),
.options-collapsed #dl-menu #navbarCollapse ul .nav-item .dropdown-toggle {
  border-radius: 0;
  margin: 0;
  background-color: white;
}

.options-collapsed .hero-logo {
  text-align: center !important;
}

.options-collapsed .header-btn {
  text-align: center !important;
}

.options-collapsed .view-link {
  display: none;
}

.options-collapsed .card-body label {
  text-align: center !important;
}

.options-collapsed .mobile-padding {
  padding: 0 15px;
}

.options-collapsed #id_label {
  text-align: left !important;
  width: 20%;
  padding: 0;
}

@media screen and (max-width: 576px) {
  .mobile-save-btn {
    text-align: center !important;
    margin-bottom: 25px;
  }

  .name-logout {
    position: initial; /* move to created second line of header */
  }

  .dl-menu .nav-item {
    min-width: 155px; /* This just makes Trust Center not wrap but it should be able to still wrap if needed in the future */
  }
}

@media screen and (max-width: 366px) {
  .name-logout {
    position: absolute;
    bottom: 10px;
    left: 0;
  }

  #dl-menu #navbarCollapse {
    left: 0;
    right: unset;
  }

  #dl-menu {
    top: -7px;
    left: 6px;
  }
}
.dropdown-menu {
  left: auto !important;
  margin: 0;
}

.dropright .dropdown-menu {
  left: 100% !important;
  margin-left: 0 !important;
}

.navbar a:hover,
.navbar span:hover {
  color: rgb(248, 172, 89) !important;
}

.name-logout,
.name-logout a {
  color: white !important;
}

a {
  color: #00788a;
}

.my-vendors-table-btn {
  color: #00788a;
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  font-style: italic;
}

.my-vendors-table-btn:hover {
  text-decoration: underline;
}

.nav-tabs .nav-link {
  border-color: #ecf0f1 #ecf0f1 #ecf0f1;
  background-color: rgba(213, 213, 213, 0.3);
  color: rgb(129, 134, 138);
}

.nav-item.nav-link a:hover,
.nav-item.nav-link span:hover {
  color: rgb(248, 172, 89) !important;
  background-color: red;
}

.footer-text-big {
  font-size: 22px;
  color: #014f6e;
}

.footer-text-small,
.footer-text-small a {
  font-size: 18px;
  color: #014f6e;
}

.smaller-text {
  font-size: 0.8rem;
}

.section-container {
  background-color: #f6f6f6;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.section-container .btn.btn-danger {
  font-weight: bold;
  border: 1px solid white;
  min-width: 260px;
}

.section-container.is-busy-centered {
  justify-content: center;
}

div.compliance-section-container {
  border: 3px solid #5a798b;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
}

div.fi-section-container {
  border: 3px solid #5a798b;
  background-color: white;
  max-height: 600px;
  overflow: auto;
  margin-left: 0;
  margin-right: 0;
}

.fi-section-container.fi-map {
  max-height: none;
}

.fi-section-container h5 {
  color: #5a798b;
}

.fi-section-container .fi-summary {
  padding: 0 1%;
}

.summary-section-average-score {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  padding: 5px 10px;
  width: 160px;
  z-index: 0;
}

.summary-section-average-score div {
  margin-bottom: 10px;
}

.summary-section-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  padding: 10px;
  line-height: normal;
  font-size: 0.75em;
}

.summary-section-info p {
  margin-left: 2.2em;
}

.summary-section-info button {
  font-weight: bold;
  padding: 6px 30px;
  margin-top: 10px;
  border: 1px solid white;
}

.summary-section-details {
  padding: 20px 30px 0;
  columns: 3;
  width: 75%;
  line-height: 3em;
}

.summary-section-details ul {
  padding: 0;
}

.summary-section-details li {
  font-weight: bold;
  margin-bottom: 10px;
  list-style-type: none;
  display: inline-block;
  width: 100%;
  text-align: right;
}

.summary-section-container {
  margin: 0 10px 20px;
  padding: 20px 10px 10px 120px;
  color: #9d9d9d;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgb(0, 0, 0, 0.6);
  min-height: 260px;
}

.details-with-flags-section-container {
  margin: 0 10px 20px;
  padding: 20px 10px 10px 20px;
  color: #9d9d9d;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgb(0, 0, 0, 0.6);
  min-height: 160px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.summary-section-container.fi-summary {
  padding: 0px 0px;
  color: black;
}

.locked-section-container {
  margin: 0 10px 20px;
  padding: 20px 10px 10px 120px;
  color: white;
  background-color: #5a798b;
  border: 1px solid white;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgb(0, 0, 0, 0.6);
}

.locked-section-container.col-9 {
  margin: 20px 15% 20px;
}

.locked-section-icon {
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: white;
  font-size: xx-large;
  text-align: center;
  padding-top: 3px;
  margin-top: 30px;
  margin-left: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: radial-gradient(farthest-corner at 0% 0%, #008bce 60%, #007ec8 30%);
}

.locked-section-button {
  font-weight: bold;
  border: 1px solid white;
  position: absolute;
  right: 40px;
  bottom: 20px;
}

.section-heading {
  color: white;
  background-color: #5a798b;
  text-align: center;
  font-size: large;
  font-weight: bold;
  padding: 6px 0;
  margin-bottom: 12px;
  width: 100%;
}

.section-container.with-header .section-heading {
  margin-bottom: 0;
}

.section-container-header {
  padding: 10px 35px 9px 35px;
  display: flex;
  width: 100%;
  background: #c8ced1;
}

.section-container-header .header-label {
  font-size: 18px;
  font-weight: bold;
  width: 25%;
  position: relative;
  top: 4px;
}

.section-container-header .header-search-container {
  width: 50%;
  text-align: center;
}

.section-container-header .header-search {
  background: white;
  border-radius: 5px;
  border: 1px solid #8fb1bf;
  display: flex;
  margin: -2px 0;
}

.section-container-header .header-searcher {
  padding: 5px 5px 5px 15px;
  display: flex;
  flex: 1;
  border: 1px solid #8fb1bf;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.section-container-header .filter-btn {
  background: white;
  color: #617186;
  border: 1px solid #8fb1bf;
  border-left-color: #e8eef1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}

.section-container-header .filter-btn.filters-applied {
  color: #2196f3;
  border-color: #2196f3;
}

.section-container-header .filter-btn:hover {
  background: #014f6e;
  color: white;
  fill: white;
}

.section-container-header .filter-btn.filters-applied:hover {
  background: #2196f3;
  color: white;
  fill: white;
}

.section-container-header .filter-btn i {
  padding-right: 3px;
}

.section-container-header .header-search .fa-search {
  position: relative;
  top: 5px;
  color: gray;
  padding-right: 5px;
}

.section-container-header .header-searcher input {
  flex: 1;
  border: 0;
  outline: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.section-container-header .header-btns {
  width: 25%;
  text-align: right;
}

.section-container .header-btns > button {
  margin-left: 15px;
  padding: 5px 9px;
  border-radius: 5px;
  border: 1px solid #014f6e;
  background: #014f6e;
  color: white;
}

.section-container .header-btns > button.cancel-btn {
  border-color: #6c757d;
  background: #6c757d;
}

.section-container .header-btns > button:hover {
  background: #003e56;
  border-color: #003e56;
}

.section-container .header-btns > button.cancel-btn:hover {
  background: #4e5357;
  border-color: #4e5357;
}

.section-container .header-btns > button i {
  margin-right: 5px;
}

.section-main-title {
  color: #395464;
  font-size: large;
  font-weight: bold;
  margin: 0 10px;
}

.section-inner-title {
  font-size: large;
  font-weight: bold;
}

.section-inner-title.red-findings {
  padding-left: 95px;
}

.section-info-text {
  font-size: medium;
  padding-top: 10px;
  word-wrap: break-word;
}

.section-details-text {
  padding-top: 20px;
}

.section-details-bullets {
  columns: 3;
  width: 60%;
  padding-top: 10px;
  padding-left: 18px;
}

.vendor-info-container {
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  background: #f6f6f6;
}

.rank-tile {
  border-radius: 15px;
  color: white;
  background-color: red;
  font-size: larger;
  font-weight: bold;
  line-height: 25px;
  width: 55px;
  height: 55px;
  text-align: center;
  display: inline-block;
}

.rank-tile-value {
  font-size: xx-large;
}

.rank-tile-cyber-hygiene-grade {
  display: block;
  margin-top: 25%;
}

.ch-card-container {
  columns: 4;
  width: 75%;
  line-height: 1em;
}

.ch-card {
  width: 100%;
  margin-left: 1em;
}

.ch-ss-label-base {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 5em;
  min-height: 3.7em;
  padding: 0.45em 2em 0.45em 2em;
  border-radius: 5px;
}

.ch-ss-label-base span {
  /* font-weight: bold; */
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 1em;
  color: #fff;
}

.ch-ss-rank {
  font-size: 0.75rem;
  margin-bottom: -3em;
}

.ch-ss-rank p {
  display: inline-block;
}

.ch-chart.ch-vulns {
  width: 130%;
  margin-left: -15%;
  margin-top: 30px;
}

.ch-chart-findings-bar {
  font-size: 0.75em;
  text-align: center;
}

.has-new-line {
  white-space: pre-line;
}

.chosen {
  background-color: #ff9c04;
  color: white;
}

.not-chosen {
  background-color: white;
  color: black;
}

.clickable-icon {
  cursor: pointer;
}

.category-filter-options-container {
  position: relative;
  display: flex;
}

.category-filter-options-lists {
  position: absolute;
  z-index: 3;
  top: 35px;
  background: white;
  border: 1px solid #e6e9ec;
  border-radius: 5px;
  padding: 5px;
  width: 250px;
}

.category-filter-search-section {
  display: flex;
  background: #dfe1e3;
  border-radius: 5px;
}

.category-filter-search-container {
  background: white;
  padding: 2px 10px;
  display: flex;
  flex: 1 1;
  margin: 5px;
  border: 1px solid #c1c7cd;
  border-radius: 5px;
}

.category-filter-search-container input {
  flex: 1;
  border-radius: 5px;
  border: 0;
  outline: 0;
}

.category-filter-selection {
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.category-filter-selecter {
  text-align: left;
}

.category-filter-selecter option {
  background: white;
  color: black;
}

.reset-btn-container {
  text-align: right;
  min-width: 60px;
}

.reset-btn-container button {
  padding: 5px 9px;
  border-radius: 5px;
  border: 1px solid #6c757d;
  background: #6c757d;
  color: white;
}

.reset-btn-container button:hover {
  background: #4e5357;
  border-color: #4e5357;
}

.category-filter-selection select {
  background: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 9px;
  margin-right: 4px;
  cursor: pointer;
}

.category-filter-options {
  margin: 5px 5px 0 5px;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  text-align: left;
}

.category-filter-options .no-results {
  text-align: center;
}

.category-filter-options .filter-option {
  cursor: pointer;
  padding: 2px;
}

.category-filter-options .filter-option:hover {
  background-color: #d7f2ed;
}

.category-filter-options .filter-option.option-selected:hover {
  background-color: #ffd5d5;
}

.category-filter-options .filter-option input {
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.product-management-view-container {
  padding: 10px 20px 20px 20px;
  background: white;
  border: 1px solid #c1c7cd;
  border-top: 0;
  width: 100%;
}

.breach-monitoring-table {
  max-height: 500px;
  overflow-y: scroll;
}

/* This let's us animate an ellipsis (...)
for any text whose div has the className "animated-ellipsis" */
.animated-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
/* ------------------------------ */

@media (min-width: 1281px) {
  .ch-ss-label-base {
    min-width: 5em;
    font-size: 0.8em;
  }
  .ch-ss-label-base span {
    min-width: 5em;
  }
  .ssi-media-query {
    width: 20%;
    padding: 0.5em;
  }

  .section-container-header .filter-btn:not(.filters-applied):before {
    content: ' ';
    height: 36.5px;
    width: 5px;
    border-top: 1px solid #8fb1bf;
    border-bottom: 1px solid #8fb1bf;
    position: absolute;
    top: -1px;
    left: -3px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.protected-file-item-container {
  min-width: 420px;
  max-width: 420px;
  min-height: 80px;
  margin: 10px;
  color: black;
  box-shadow: 1px 0 0 0 lightgrey, 0 1px 0 0 lightgrey, 1px 1px 0 0 lightgrey, 1px 0 0 0 lightgrey inset,
    0 1px 0 0 lightgrey inset;
  background: #eaeaea;
  position: relative;
}

.protected-file-item-container.upload-request {
  background: #ffebcc;
}

.scan-overlay {
  position: relative;
}

.scan-overlay .protected-file-item-info,
.scan-overlay .protected-file-item-buttons-container {
  opacity: 0.5;
}

.scan-status-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  border: 1px dashed black;
}

.scan-status-overlay > div {
  text-align: center;
  color: white;
  width: 100%;
  padding: 2px 0;
  font-size: 16px;
  position: absolute;
  bottom: 0;
}

.scan-status-overlay > .scan-status-nostarted {
  background: #7b8a8b;
}

.scan-status-overlay > .scan-status-scanning {
  background: #ff9c04;
}

.scan-status-overlay > .scan-status-invalid {
  background: #e74c3c;
}

.protected-file-item-file-icon {
  font-size: 24px;
}

.protected-file-item-share-icon {
  font-size: 24px;
  float: right;
  cursor: pointer;
}

.protected-file-item-info {
  margin: 10px 10px 40px 10px;
}

.protected-file-item-info > .file-class {
  float: right;
  font-weight: bold;
  width: 50%;
}

.protected-file-item-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.protected-file-item-button {
  box-shadow: 1px 0 0 0 darkgrey, 0 1px 0 0 darkgrey, 1px 1px 0 0 darkgrey, 1px 0 0 0 darkgrey inset,
    0 1px 0 0 darkgrey inset;
  padding: 5px 0;
  text-align: center;
}

.mh-300 {
  min-height: 300px;
}

.x-close-button-top-right {
  float: right;
  cursor: pointer;
  font-weight: bold;
  margin-right: -10px;
  margin-top: -15px;
}

.info-box {
  margin: 0 auto;
  width: 80%;
  padding: 8px;
  background: #deebff;
  display: flex;
  border-radius: 3px;
}

.info-box > div:first-child {
  padding-right: 10px;
  color: #0052cc;
}

.info-box > div:first-child > svg {
  position: relative;
  top: -1px;
}

.info-box > div:last-child > p {
  margin: 0;
}

.link-text {
  text-decoration: none;
}

.link-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.video-flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: normal;
  align-content: center;
}

.video-flex-item {
  flex: 0 0 auto;
  margin: 25px;
  display: block;
  width: 400px;
  height: 270px;
  box-shadow: 0 3px 6px rgba(56, 56, 56, 0.16), 0 3px 6px rgba(56, 56, 56, 0.16);
  border-radius: 5px;
  padding: 10px 10px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.wistia-div {
  height: 85%;
}

.a2v-logo-heading {
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 200px;
  padding: 30px 0;
}

.text-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-size-medium {
  font-size: medium;
}

.sbom-option > span {
  margin: 0 3px;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.sbom-option .supplier-provided-sbom {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAMAAAD+iNU2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEsUExURQAAAAAAAAAAABwcHCQkJCEhISAgIB0dHR4eHh0dHSAgIB8fHx8fHyIiIiIiIiAgICIiIiAgICEhISEhISEhISAgIB8fHyEhISAgICIiIiAgICAgICIiIiEhISAgICEhISAgICEhISEhISEhIR8fHx4eHhwcHB4eHh0dHR8fHyIiIiAgICEhIR0dHSEhIR4eHiIiIhwcHBsbGxoaGhsbGxwcHB0dHR4eHh8fHyAgICEhISIiIiUlJScnJykpKS4uLjQ0NDk5OTo6Oj09PT4+PkFBQUJCQkhISElJSU1NTVZWVldXV1xcXF5eXmtra319fX9/f4GBgYSEhIWFhZmZmampqaqqqru7u76+vtnZ2efn5+jo6O3t7fPz8/T09Pb29vf39/n5+fr6+v///3k/85cAAAAzdFJOUwADBAkVFxgaIiMoKTE0NTg8P2Rla3F7e39/gIGJlJakvsDGy8zN3d3e6urs7vDy+Pj8/caOcHcAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADDSURBVBhXY8ACGAUlpSSkRdhhXDEtSxNzUyslHghX1MDSNijU29RamQvE59e2Mg5ITo5yMrNWYAXyZaytTMOSk5N9jK30+IB8OWsrE7+k5EgHcysdXiBf1trKysw32NnUCsa3NLFzdXe0MoHyrWwCI+MTY8O9LMB8ORN/oGlAEOumCzJPyNA7BsyPsFdlA/JZFMw9I+ISokPs9YWBXAYGbkUjaxcPBzNNcSYwn4FVREVDTV1egBHCBQI2Tg4OZiDNwAAAKn8jwsSWw7sAAAAASUVORK5CYII=');
}

.sbom-option .open-source-sbom {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAMAAADarb8dAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC9UExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOvSc8wAAAA+dFJOUwABAwQGBwsOFyQqKzU2OTw9P01OUVJTWGZsbXR4eYiVm6Omq6ytrrO0vL7FyMrMzc/S09Tg5OXm8fLz+fr7J2XA2QAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAKxJREFUGFdty8cSgkAQANEGQRABcxbEhBlMmFb4/89yFU+Wc5p+NcPfUav+fO5X1G9SWqXxeBynq1Le1jWyFVCc6GK9W9tsDdB1MLYbTUIjtVG989lTsdO6hNFeoSuGQ9FF2Y8kLBYQrmEd5jvBDgb3VvsxgF0goSMsjGQySQzKoiOheFriPGs1YbM8FSXg3qazQ6FwmE1v7rvBOWY96GdHJ2/51TLBbH7ufwZenC0PUDVtRAIAAAAASUVORK5CYII=');
}

.sbom-option .fortress-created-sbom {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAECAYAAACHtL/sAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAD1SURBVChTY/wPBD9+/2HgDCxgMNNUZjjZXcgAAlkzVjJsPHWFgY2FmSHEUp9h06mrDOG2RgxNUV5g+RWHzzIUzV3PwAAyQDi64n/zyh3/pRPr/3es2QMS+u/XMgtM/wNCBpOI/8dv3P/PYBr5/+Grt2BxkNi9F2/+M0zYtP8/c2A+WPDp2w//GfTD/3/48u3/jScv/+vktP0Xiqr4v/nUZbD8tG2H/vNHlP3Xz+/4nz51BViM+ZWEVsO53lIGHk52Bl5ODgZxeUmG2btPMOw4e5Xh55+/DNpyEgxAAxhc9NQZ3Aw1Ga48fgH0FhPDspIEoEcYGAA5hIh8urF7PwAAAABJRU5ErkJggg==');
  height: 4px;
  top: -3px;
}

.sbom-option .redirect-icon {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAMAAAD+iNU2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFfUExURQAAAAD//1WqqjOZmVWAqkCAnzlxjjlxqk2AmUCAlUmApEBwj0CAnzx4lkt4lkuHpUCAn0d6mUJ7lz57lUSAmUSAokaDm0OAmkR8m0B6nEJ7nEF9mUB7mz95nUR7nUF+nEN8nEN8mkN5nEF5m0F8m0F7nUR7nUB9m0J7nEF6m0B6mkJ6nUJ8m0F6nEB8m0B5nEN8nEJ7nUJ7nEJ5m0N7m0J8m0J6nUF7m0F7nEN8m0N8nkN+nkJ7nER7m0N6m0F7nEJ7nEJ6nEF7m0N8nUJ7m0J8nkJ8nUF7nER/oUN8nEJ7m0J7nUJ7m0J8nEN+oEaCpEJ6nEJ7nUJ8nEN9nUJ7nEJ7m0N7nER+oEN9n0N/oEJ7m0J6nER+n0J7nEN8nkN+oEJ7nEN8nkJ7nEJ8nkN+oER+oUJ7nEN8nUN+oER/oUV/okWAokWBo0WBpEaCpUaDpkaDp0eDp0eGqUiHq0yNs1JbbJoAAABmdFJOUwABAwUGCAkJCgwOEBAREREYGRsdHh4hJiksNjc4OTxDSExQUlJTU1xdXmBgYWJjZ29wdHh6e3+FiYqMjJOXmaCio6SkpqarrK2vs7O1tba3vMHQ1drk5ubn5+ns8PH5+/z8/v7+/pRH9+AAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC9SURBVBhXYwABRWEwBQNCYcHSQIpLUECQE8Rn0Q2P0WZgD4gIi7QB8Rkk7LPiDTiiCnOLfIE8HufEjLRsM0Z9K0trNQYGNs88L6O4IC6wSiAwzrdgME1VhvIYeEMDmbljvZiUtDQ1NXWkGeTSVRhYTST5onMyMzPz3RnUk8RAyvhDkhMSElIcGWRd+MD6xGWkpKRkRMBsfEDFX4NB1M3XBwz8DBnMS2wZ5PML8sGg2JtBwUOVQdDOyQEMXPUAS4gkUWqnhksAAAAASUVORK5CYII=');
  top: 2px;
}

.alert.alert-dismissible.fade.show.login-alert {
  margin: 20px 20px -25px 20px;
}

.alert.alert-dismissible.fade.show {
  margin: 40px 20px -20px 20px;
}

.opacity-50 {
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
.hide-arrows 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.hide-arrows
input[type=number] {
  -moz-appearance: textfield;
}