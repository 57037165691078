#findings-table,
#access-requests-table {
  width: 100%;
  height: fit-content;
}

.bootstrap-table-header {
  font-size: 1rem;
  text-align: center;
}

.bootstrap-table-row {
  font-size: 0.85rem;
  text-align: center;
}

#findings-table .bootstrap-table-row td:nth-child(3) {
  text-align: left;
}

#findings-table .row-expansion-style p {
  white-space: pre-wrap;
}

.react-bootstrap-table tbody th .filter-label,
.bootstrap-table-row .filter-label {
  width: 100%;
}

.react-bootstrap-table .bootstrap-table-header .expand-cell-header,
.react-bootstrap-table .bootstrap-table-row .expand-cell {
  width: 50px;
  cursor: pointer;
}

.react-bootstrap-table .expand-cell {
  vertical-align: middle;
}

.bootstrap-cell-formatter {
  display: block;
  padding: 5px 20px 5px 30px;
  margin: auto;
  border-radius: 50px;
  width: 75%;
  text-align: center;
}

#access-requests-table .row-expansion-style {
  padding: 10px 20px;
  font-size: 13.6px;
}

.bootstrap-cell-formatter.canEdit {
  cursor: pointer;
}

#findings-table .react-bootstrap-table-page-btns-ul {
  margin-top: 5px;
}

#access-requests-table .companyLogo {
  width: 100px;
}

#access-requests-table th:first-child {
  width: 130px;
}

#access-requests-table th:nth-last-child(2) {
  width: 210px;
}

#access-requests-table td:nth-child(4) {
  overflow-wrap: break-word;
}
