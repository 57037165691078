.p-section-header {
  display: inline-block;
  background: var(--brandPrimary);
  border-bottom: 4px solid var(--brandSecondary);
  font-weight: 300;
  font-size: 18px;
  width: 100%;
  color: #fff;
  padding: 10px 0 10px 10px;
  margin-bottom: 10px;
}

.postern-section {
  page-break-before: always;
}
