.provenance-table {
    border: 1px solid #173059;
    padding: 5px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.provenance-theader {
    padding: 5px;
    background: #2557a9; /* proposed - #2557a9 old - #173059 */
    color: #fff
}

.provenance-table-row {
    border-bottom: .5px solid #173059;
}

.provenance-risk-col {
    width: 35px;
}

.provenance-table td, .provenance-table th{
    padding: 0 15px 0 15px;
}