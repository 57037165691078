.provenance-tileBody {
    width: 220px;
    min-height: fit-content;
    text-align: center;
    color: #fff;
    padding: 15px;
    margin: 15px 0px;
}

.tile-with-footer {
  height: 180px;
}

.tile-footer b,
.tile-footer p {
  font-size: larger;
}

.provenance-highlightedTileSection {
    background: rgba(255,255,255,.35);
    padding: 7px;
    width: 90%;
    margin: auto;
    text-align: center;
}


.provenance-tileBody .provenance-titleTile {
    line-height: 20px;
    max-height: 40px
}

#postern-provenance .warning {
    background-color: #ed5565
}

#postern-provenance .safety {
  background-color: #1ab394;
}