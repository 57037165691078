.login-page {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

.login-page > section {
  overflow: auto;
  width: 100%;
  margin: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}

.login-container > .card-login > .card-header {
  margin-bottom: 0;
}

.login-container > .card-login > .card-header > p {
  margin-left: 20px;
  color: #327688;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: large;
}

.login-container > .card-login > .card-body {
  margin-top: 10px;
}

.login-page-label {
  margin-left: 20px;
  color: #327688;
}

.form-control.login-page-input {
  margin-left: 20px;
  margin-right: 20px;
  max-width: -webkit-fill-available;
  height: 45px;
  font-size: 17px;
  width: -moz-available;
}

.login-container > .card-login > .card-body > form > button {
  margin: 45px 20px 0 20px;
  max-width: -webkit-fill-available;
  height: 45px;
  font-weight: bold;
  font-size: 20px;
  padding-top: 1px;
  border-radius: 15px;
  width: -moz-available;
}

.forgot-password {
  font-size: large;
  margin-bottom: 30px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.forgot-password > div {
  margin-top: 14px;
}

.forgot-password > div > a > p {
  color: #327688;
  margin-left: 40px;
  font-size: 0.85rem;
  margin-top: 25px;
}

.fortress-logo {
  margin-top: 3rem;
}

.fortress-logo > div {
  text-align: center;
  margin-top: 75px;
}

.fortress-logo > div > img {
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 100px;
}

.vendor-product-count-container {
  display: flex;
  background: rgba(247, 239, 239, 0.33);
  height: 112px;
  margin-top: 645px;
}

.vendor-product-count-container.lower {
  margin-top: 700px;
}

.vendor-product-count {
  display: inline-block;
  text-align: center;
  width: 100%; /*'50%' - Temporarily increased to 100% until we have more products.*/
}

.vendor-product-count > h3 {
  color: white;
  font-weight: 300;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white, -1px -1px white, 1px 1px white, -1px 1px white,
    1px -1px white;
  margin-bottom: 0px;
  margin-top: 21px;
}

.vendor-product-count > h4 {
  color: white;
}

.vendor-product-count-vertical-line {
  border-left: 3px solid white;
  border-radius: 99px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
}

.login-container > .card-login {
  max-width: 45rem;
  min-height: 37rem;
  max-height: 45rem;
}

.login-checkbox > div {
  margin: 20px 0 -30px 25px;
}

.login-checkbox > div > input {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  position: relative;
  margin-left: 0;
  cursor: pointer;
}

.login-checkbox > div > input.on-focus {
  outline: 2px solid red;
  outline-offset: 2px;
}

.login-checkbox > div > label {
  color: #327688;
  font-size: 0.85rem;
  font-weight: 600;
  margin-left: 0;
  cursor: pointer;
}

.login-checkbox > div > label > button,
.login-checkbox > div > label > p {
  padding-left: 0;
  border: none;
  color: rgb(50, 118, 136);
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
}

.login-checkbox > div > label > button:hover {
  background: none;
  text-decoration: underline;
}

.access-conditions-modal > div > ul > li,
.access-conditions-modal > div > ul > ul > li,
.access-conditions-modal > div > ul > ul > ul > li {
  margin-bottom: 1rem;
}

.access-conditions-modal > div:first-child {
  position: sticky;
  top: 0;
  padding: 20px 0 0 20px;
  margin-left: -20px;
  background: white;
  height: 70px;
}

.access-conditions-modal-close-back-btn {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: sticky;
  bottom: 0;
  background: white;
  margin-top: 20px;
}

.access-conditions-modal-close-back-btn > button {
  min-width: fit-content;
}

.user-agreement-btn {
  padding: 0;
  border: none;
  color: rgb(50, 118, 136);
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
}

.user-agreement-btn:hover {
  background: none;
  text-decoration: underline;
}

ul.circle {
  list-style-type: circle;
}

ul.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}

ul.dash > li:before {
  display: inline-block;
  content: '-';
  width: 1em;
  margin-left: -1em;
}
