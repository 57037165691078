.page-container {
  width: 100%;
  padding: 0 5em;
  margin-top: -20px;
}

.overview-no-cmre-data-notice {
  width: 100%;
}

.overview-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.overview-wrapper {
  display: flex;
  width: 25%;
  min-width: 25%;
  max-height: 100px;
  align-items: flex-end;
  margin-bottom: -1px;
}

.overview-label {
  color: #7f7f7f;
  display: inline-block;
  font-size: 1.3vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.on-hover-text {
  margin-bottom: 0;
}

.on-hover-tooltip {
  display: block;
}

.claimed-page-icon {
  margin-top: -4px;
  margin-left: 3px;
  display: inline-block;
  color: #7f7f7f;
}

.claim-page-button {
  margin-bottom: 10px;
  text-align: end;
  height: 36px;
}

.add-remove-my-vendor-head-btns-wrapper {
  display: flex;
}

.add-remove-my-vendor-button {
  text-align: end;
}

@media only screen and (max-width: 1610px) {
  .claim-page-button {
    margin-bottom: 10px;
    text-align: end;
    height: 36px;
  }

  .add-remove-my-vendor-button {
    text-align: end;
  }
}

.icon-btn {
  background: none;
  border: 0;
  padding: 0;
  color: gray;
}

.head-btns {
  display: flex;
}

button.head-btn,
button.choice-btn {
  font-size: 1rem;
  padding: 5px 25px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.subtab-list-header {
  font-weight: 700;
  margin: 10px 0;
}

.choice-btns {
  display: inline-block;
  margin-left: 15px;
}

.link-icon-div {
  margin: 5px -5px 5px 5px;
}

i.link-icon {
  font-size: 25px;
  cursor: pointer;
}

.tab-list {
  text-align: center;
  margin: 5px 0 10px 0;
}

.tab-list-item {
  display: inline-block;
  border-radius: 0.3rem;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  font-weight: bold;
}

.tab-list-active {
  background-color: #ff9c04;
  color: white;
  border: solid #ccc;
  border-width: 1px;
}

button.chosen {
  background-color: #ff9c04;
  color: white;
}

button.not-chosen {
  background-color: white;
  color: black;
}

.ddrr-report-section {
  /* height: -webkit-fill-available; */
  max-height: 80vh;
  width: 100%;
}

.trust-center-doc-section {
  height: -webkit-fill-available;
  width: 100%;
}

.overview-findings .fas {
  margin-top: -15px;
  margin-bottom: 5px;
}

#findings-table table {
  margin-bottom: 0;
}
