#dl-menu {
  background-color: transparent;
  color: white;
}

#dl-menu #navbarCollapse {
  background-color: transparent;
}

#dl-menu #navbarCollapse ul {
  margin: auto;
}

#dl-menu #navbarCollapse ul .nav-item a,
#dl-menu #navbarCollapse ul .nav-item .dropdown-toggle {
  border-radius: 20px;
  border: 1px solid white;
  margin: 0px 1.5px;
  background-color: transparent;
}

.options-collapsed #dl-menu #navbarCollapse {
  position: absolute;
  left: 1px;
  top: 30px;
  z-index: 100;
}

.options-collapsed #dl-menu .navbar-toggler {
  margin-top: 2px;
  background-color: #004e71;
  display: block;
}

.options-collapsed #dl-menu .collapse:not(.show) {
  display: unset;
  height: 0px;
  overflow: hidden;
}

@media (max-width: 576px) {
  #dl-menu #navbarCollapse {
    position: absolute;
    left: unset;
    right: 1px;
    top: 30px;
    z-index: 100;
  }
}
